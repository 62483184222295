import moment from "moment";
import { PRODUCTS } from "./constants-products";
import { useEffect, useState } from "react";
import SlackAlias from "@buddieshr/components/slackAlias";

export const capitalize = (t) =>
  `${t[0].toUpperCase()}${t.substring(1, t.length).toLowerCase()}`;

export const getRandomArbitrary = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const isLocalhost = () =>
  window && window.location && window.location.hostname === "localhost";

export const getNbInstallForToday = () => {
  // try to get nbInstalls from localstorage (to avoid having a different nb on refresh)
  // if found, then return
  // if not then compute new number, store and return
  const nbInstallList = JSON.parse(localStorage.getItem("number_installs"));
  const today = moment().format("DD/MM/YYYY");
  if (nbInstallList && nbInstallList[today]) {
    return nbInstallList[today];
  } else {
    const nbInstall = getRandomArbitrary(122, 455);
    localStorage.setItem(
      "number_installs",
      JSON.stringify({
        ...(nbInstallList || []),
        [today]: nbInstall,
      })
    );
    return nbInstall;
  }
};

export function slugify(str) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

export const gtmVirtualPageView = (rest) => {
  window.dataLayer?.push({
    event: "VirtualPageView",
    ...rest,
  });
};

export const getSubscribeUrl = (isDev, appName) => {
  let product = PRODUCTS.find((f) => f.slug === appName);
  if (product) {
    return isDev ? product.subscribeDevUrl : product.subscribeUrl;
  }
  return null;
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const getInstallLink = (refSource, installLink) =>
  `${installLink}/?ref=${refSource}`;

export const getPluralListOfElement = (elements) =>
  elements.map((name, index) => (
    <>
      <SlackAlias key={`${name}_${index}`} name={name} />
      {elements.length > 1 &&
        index < elements.length - 1 &&
        index >= elements.length - 2 && <span> and </span>}
      {elements.length > 1 && index < elements.length - 2 && <span>, </span>}
    </>
  ));

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return "";
};

export const scrollTo = (selector) =>
  document.querySelector(selector).scrollIntoView({
    behavior: "smooth",
  });

export default {};
