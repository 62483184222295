import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      metaTitlePricing:
        "Pricing and Plans - An offer you can't refuse | BuddiesHR",
      metaTitle: "Employee engagement for slack | BuddiesHR",
      title: "The All-In-One Culture platform your employees will love",
      descriptionContent:
        "Develop, recognize and engage your employees thanks to several apps for Slack. Provide the best working experience to your employees.",
      titleFooterEmployeeEngagementTools: "Employee engagement softwares",
      Resources: "Resources",
      contactus: "Contact us",
      metaTitleGetStarted: "Get started with BuddiesHR Culture platform",
      descriptionContentGetStarted:
        "Get started with the best engagement slack apps. Develop, recognize and engage your employees thanks to several apps for Slack.",
      titleAbout: "About BuddiesHR mission | Buddieshr.com",
      descriptionAbout:
        "BuddiesHR is a modular culture platform for Slack. But it's more than that. Check out our mission explained on this page.",
      installedLastWeek: "companies installed Buddies apps last week",
      awardWinningApp: "Award-winning apps for Slack",
      // rated: "Rated 4.9/5 - {{rates}}+ reviews",
      rated: "Rated 4.9/5",
      faq1Title: "Can I choose any amount of app?",
      faq1Content:
        "Yes everything is modular, you can install 1 app or 2, 3 or all of them. The pricing will be adapted.",
      faqTitle: "Frequently Asked Questions",
      faq2Title: "What happens if I want to cancel only one app?",
      faq2Content:
        "This is very easy, you have to make sure to contact us to cancel the product subscription.",
      faq3Title:
        "Do I pay for all members of my workspace or only for those using the apps?",
      faq3Content:
        "You only pay for active users. This is the most fair option for you.",
      faq4Title: "Can I get a free trial ?",
      faq4Content: "Yes, all of our apps have a 30 days free trial.",
      metaTitleCompany:
        "{{companyName}} company values, alternatives and similar companies",
      descriptionCompany:
        "Discover the company values of {{companyName}} and see alternative companies with similar values. Get details on their company culture and how they use it.",
      metaTitleValue:
        "{{valueName}} is a company value used by the best companies, list companies using it | BuddiesHR",
      descriptionValue:
        "Discover which companies use {{valueName}} as part of their company values. See alternative naming and etimology, read examples of usage and build a great culture.",
      metaTitleCompanyList:
        "Top companies and their values analyzed - Best startup values | BuddiesHR",
      descriptionCompanyList:
        "Exhaustive list of top worldwide companies with their values analyzed. Complete study on main company values and complete dataset for you to navigate through the values.",
      metaTitleValuesList:
        "Top company values used by best Startups around the world and best company culture | BuddiesHR",
      descriptionValuesList:
        "List of top company values used by the best Startups around the world. Find out what values are most used by the startups, main categories and different namings. Build the best culture.",
      metaTitleToolbox:
        "Toolbox for HRs - Suite of apps to support HR teams in daily operations | BuddiesHR",
      descriptionContentToolbox:
        "Develop, recognize and engage your employees thanks to several apps for Slack. Engaged employees are 22% more productive at work.",
      metaTitleStartupCulture: "Startup Culture Insights Report [2024 edition]",
      descriptionContentStartupCulture:
        "What do high-performing companies have in common? In order to attract and retain the best talent, they must cultivate a world-class culture. ",
      cultureReport: "Read our Startup Culture Report [2024 edition]",
      readReport: "Read report",
      metaTitleSubscribe: "Subscribe to BuddiesHR apps for Slack | BuddiesHR",
      descriptionContentSubscribe:
        "Subscribe to HR apps for Slack to engage your employees. Best apps for hybrid companies.",
      titlePaymentSuccess: "Payment successful! Thank you!",
      paymentSuccessTitle: "Payment Successful!",
      paymentSuccessSubitle1: "Thank you for your trust.",
      paymentSuccessSubitle2:
        "To find your invoices, Go into any Buddies app in Slack (bottom of the sidebar) then in the Home tab you'll find a Billing section.",
      metaTitleTopApps:
        "Best Slack apps, integrations & bots || BuddiesHR Toolkit for Slack",
      descriptionTopApps:
        "Find a curated list of the best Slack applications for different sectors and several integration categories.",
      metaTitleTopAppsSector:
        "Find the best and top Slack apps for {{sector}} to improve the employee experience.",
      descriptionTopAppsSector:
        "Here is a curated  list of must-haves Slack applications that enhance your Slack experience.",
      metaTitleTopAppsCategory:
        "Best {{appCategory}} apps for {{sector}} companies using Slack",
      descriptionTopAppsCategory:
        "As a {{sector}} company, enchance your Slack with the best apps and integrations, including {{appList}} and more",
      metaTitleSecurity: "Security | BuddiesHR",
      descriptionContentSecurity:
        "Find all information about our security processes, server locations and best practises in place.",
      titleTermsConditions: "Terms & Conditions | BuddiesHR",
      descriptionTermsConditions:
        "Terms and Conditions applying on BuddiesHR services.",
      addToSlack: "Add to Slack",
      metaTitleLinky: "Slack Org Chart Bot | #1 Slack App",
      descriptionContentLinky:
        "Build your org chart in seconds and keep it up-to-date automatically. Have a clear view on teams and department.",
      noCreditCard: "No credit card required",
      freeTrial: "30 days free trial",
      freeForever: "Free under 30 users",
      forTeamsUnder30: "We love Startups and small businesses ❤️",
      forTeamsAbove30: "For workspaces with 30 users or more",
      perUser: "Per user",
      perMonth: "Per month",
      sameFeaturesThanFreePlan: "All features from Free plan",
      unlimitedUsers: "Unlimited users",
      highPrioritySupport: "High priority support (<24h)",
      startForFree: "Start for free",
      installForFree: "Install for free",
      basicSupport: "Standard support",
      securityQuestionnaire: "Security questionnaire",
      selectNumberUsers: "Select the number of users in your workspace:",
      per: "Per",
      month: "month",
      pro: "Pro",
      enterprise: "Enterprise",
      pricing: "Pricing",
      temporaryDiscount: "TEMPORARY DISCOUNT",
      temporaryDiscountHint: "Subscribe today, keep the discount forever",
      contactUs: "Contact us",
      advanced: "Enterprise",
      advancedSubtitle: "Yearly payments - min 200 seats",
      talkToSales: "Talk to sales",
      yourSubscription: "Your subscription",
      tailoredPlan: "Each plan is tailored to every company's need.",
      freeForYou: "{{appName}} is Free for you",
      startSubNow: "Start subscription now",
      perMonthFor: "per month for",
      paymentSuccessTitle: "Payment Successful!",
      paymentSuccessSubitle1: "Thank you for your trust.",
      paymentSuccessSubitle2:
        "To find your invoices, Go into {{appName}}'s section app in Slack (bottom of the sidebar) then in the Home tab you'll find a Billing section.",
      goToSlack: "Go to Slack",
      contactUs: "Contact us",
      pricingPageSubtitle:
        "We want {{appName}} to be a no-brainer for every company, that's why we keep our price as low as possible.",
      titlePricing:
        "Pricing, use {{appName}} for free | {{fullAppName}} for Slack",
      descriptionPricing:
        "Find the plan that applies best for your company. We provide a free plan forever for small companies. Use {{fullAppName}} for free.",
      titleSupport: "Support | BuddiesHR Slack apps",
      support: "Support",
      supportContact1: "You can contact BuddiesHR",
      supportContact2: "support over this email:",
      supportResponse: "We respond in a maximum of 24h during week days.",
      descriptionContentSupport:
        "Contact BuddiesHr suport team for any issue or question you may have.",
      home: "Home",
      titlePaymentSuccess: "Payment successful! Thank you!",
      descriptionPaymentSuccess: "Payment successful for {{appName}}",
      successFinishSetup: "Finish Setup in Slack",
      successAllSet: "All set, thank you!",
      successAdded: "{{appName}} has been added to your Slack.",
      successToFinishSetup: "To finish the setup, ",
      successToFinishSetup2:
        "you can go in the Home tab of the application in Slack.",
      whatToDoNext: "What to do next?",
      titleSuccess: "Success, thank you!",
      descriptionSuccess: "{{appName}} has been installed in your workspace",
      metatitleCostTurnover:
        "Cost of employee turnover - How to calculate turnover cost?",
      descriptionCostTurnover:
        "Employee turnover calculator. Formula details. What is the cost of employee turnover? Why is it important to reduce employee turnover? How to reduce employee turnover?",

      metaTitleBestVirtualWatercooler:
        "Best Virtual Water Cooler for remote and Hybrid teams | Buddieshr.com",
      descriptionContentBestVirtualWatercooler:
        "Discover the best virtual water cooler for remote teams. Strengthen connections and promote collaboration by connecting employees together.",
      titleSetupClappy: "Setup Clappy in Slack",
      titleSetupAlfy: "Setup Alfy in Slack",
      descriptionSetupClappy:
        "You'll be able to edit these settings at any time inside your Slack workspace",
      descriptionSetupAlfy:
        "You'll be able to edit these settings at any time inside your Slack workspace",
      setupChooseWhichChannel: "Choose which channel to use",
      explanationPrivateChannelClappy:
        "Clappy can't be used in a private channel. The purpose of public recognitions is for everyone to see.",
      lookingForPrivateChannel: "Looking for a Private channel?",
      setupChooseClaps: "How many claps per week per person?",
      submit: "Submit",
      next: "Next",
      skip: "Skip",
      setupAlreadyDone: "Setup was already done",
      setupAlreadyDoneContent1:
        "To update the settings, you can now go into your Slack app > Click {{appName}} in the sidebar > Home tab. ",
      setupAlreadyDoneContent2:
        "Please reach out to us for any question at {{email}}",
      birthdayBotAlternatives: "BirthdayBot alternative",
      manualInstallInfo:
        "There's one final step to complete the setup: add a channel in Clappy's admin settings and set the number of claps per person. Here's how you can do it.",
      manualInstallInfoAlfy:
        "There's one final step to complete the setup: add a program in Alfy's admin settings. Here's how you can do it.",
      manualInstallStep1: '1. Search for "Clappy" in your Slack workspace.',
      manualInstallStep1Alfy: '1. Search for "Alfy" in your Slack workspace.',
      manualInstallStep1Billy: '1. Search for "billy" in your Slack workspace.',
      manualInstallStep2: "2. Click update settings ",
      manualInstallEnd: "Enjoy ✌️",
      viewPricing: "View Pricing",
      manualInstallInfoBilly:
        "There's one final step to complete the setup: add a channel in Billy's admin settings. Here's how you can do it.",
      manualInstallStep2Billy: "2. Add an active channel.",
      watchADemo: "Watch a Demo",
      metaTitleClappy: "Slack Kudos App | No. 1 Slack Recognition Bot",
      descriptionContentClappy:
        "Facilitate authentic and meaningful appreciation among your employees while putting your company values to life.",
      metaTitlePulsy: "Slack Engagement Survey Bot | #1 Slack App",
      descriptionContentPulsy:
        "Broadcast polls and surveys where your team lives. Track eNPS over time. Get answers fast with automated reminders. ",
      bestSlackPollApp: "Best Slack Poll and Survey App in 2024",
      titleBestPoll: "Best Slack Poll and Survey App for your team",
      descriptionBestPoll:
        "Looking for the best poll app for Slack? Here is the most simple way to launch a poll and get answers from surveys. Get flexibility and smart features like follow ups and reminders with Pulsy Survey.",
      metaTitleAlfy: "Slack Coffee Matching App | #1 Slack HR Bot",
      metaTitleDonut:
        "Donut alternatives, try Alfy Matching - Strengthen team bonding by regularly connecting coworkers for a virtual chat",
      descriptionContentDonut:
        "Find why Alfy is better than Donut for connecting employees. Match colleagues every week to have a chat together.",
      metaTitleAirspeed:
        "Coffee Talk by Airspeed alternative, try Alfy Matching - Strengthen team bonding by regularly connecting coworkers for a virtual chat",
      descriptionContentAirspeed:
        "Find why Alfy is better than Airspeed for connecting employees. Match colleagues every week to have a chat together.",
      metaTitleRandomCoffee:
        "RandomCoffee alternative, try Alfy Matching - Strengthen team bonding by regularly connecting coworkers for a virtual chat",
      descriptionContentRandomCoffee:
        "Find why Alfy is better than Random Coffee for connecting employees. Match colleagues every week to have a chat together.",
      headline: "Strong relationships boost people.",
      descriptionContentAlfy:
        "Match colleagues every week to have a chat together. Break down silos and improve team bonding, collaboration and culture.",
      metaTitleshuffl:
        "Shuffl AI alternative, try Alfy Matching - Strengthen team bonding by regularly connecting coworkers for a virtual chat",
      descriptionContentshuffl:
        "Find why Alfy is better than Shuffl.ai for connecting employees. Match colleagues every week to have a chat together.",
      compareFeatureTitle: "Discover how {{name}} compares with Alfy",
      compareFeatureSubtitle:
        "Enjoy a detailed comparison table about features and pricing",
      unlimitedIntros: "Unlimited intros",
      chooseGroupSize: "Choose group size",
      advancedMatchingOptions: "Advanced Matching options (team, interests...)",
      chooseMatchingFrequency: "Choose Matching Frequency",
      pricePerUser: "Price per user",
      pricedonut: "$4 per user per month",
      priceairspeed: "$3 per user per month",
      pricerandom: "~$1.4-2.8 per user per month",
      pricealfy: "$1",
      calendarPage: "Connect Calendar",
      calendarDescription:
        "Connect Alfy to your calendar to improve time slots suggestions!",
      showMoreComparison: "Show all comparison",
      showLessComparison: "Show less comparison",
      freeTrial: "30 days free trial",
      g2rate: "G2 rate",
      smartFeatures: "Smart features",
      seeMore: "View 6 more",
      metaTitleBilly:
        "Slack Birthday Bot | Automate Birthdays and Work Anniversaries",
      descriptionContentBilly:
        "Celebrate birthdays together at work. Use Billy Birthday bot to automate birthdays and work anniversaries in Slack. Remote workers need social attention. ",
      whyUse_neverforget_1: "Celebrate birthdays",
      whyUse_neverforget_2: " together at work",
      funCopywriting: "Fun & Customizable Copywriting",
      weekendFeature: "Week-end management",
      optoutOption: "Opt-out option",
      howItWorks: "How it works",
      happensInSlack: "Everything happens in Slack",
      followTheseSteps: "Follow these few steps to get started.",
      quoteKatrina:
        "Billy the birthday bot makes it easy for either account owners or the user themselves to enter their birthday info. Bonus points for the customer care team being attentive and timely in their responses!",
      quoteAlexandra:
        "The worst feeling is interacting with a co-worker all day long and getting home to see on social media that it was actually their birthday. Now that we have Billy, we are all notified first thing in the morning through Slack about our co-workers Birthdays and it has been such a blessing!",
      fromKatrina:
        "Katrina Ross, Executive Director @ Aggieland Humane Society",
      fromAlexandra:
        "Alexandra Sohn, Director of Marketing & Public Relations @ Faithful Platform",
      quotemary:
        "Our team is loving Billy, Alfy & Pulsy!! Thank you for being so creative and keeping it up:)",
      frommary: "Mary Vanesyan",
      quotejulia:
        "Billy allowed us to save time and efficiency in celebrating our employees' birthdays and thus creating team cohesion. This is very easy to set up, it's quite intuitive. I recommend",
      fromjulia: "Julia Devienne",
      quotetoma:
        "We really like and enjoy the app, keep up the good work! Thank you so much.",
      fromtoma: "Toma Stefanescu",
      fromLaura:
        "Laura Winegardner - Senior Employee Experience Manager @ May Mobility",
      fromEfe: "Efe Ebueku - People Operations @ Payfi",
      quoteLaura:
        "I've been trying to find an affordable solution to automate the celebration of employee birthdays and anniversaries for over a year now. Very appreciative to the Billy Birthday team!",
      quoteEfe:
        "Billy is awesome and has helped me to focus on other things. Celebrating birthdays was a bit difficult prior to Billy.",
      quoteSarah:
        "We are happy to have Billy installed on our Slack, the employees are delighted to receive a little attention on their birthday.",
      fromSarah: "Sarah Khau, Office Manager @ smallable",
      quoteLorena:
        "We're absolutely thrilled with Billy. It was incredibly easy to implement, and our entire team adores it. ",
      fromLorena: "People and Culture Coordinator @ SafetyWing",
      quoteVictor:
        "Billy Birthday has been amazing. It's very intuitive and easy to configure my preferences. I had no problems dealing with Billy Birthday and I loved the functionality of uploading an xls file to import birthdays.",
      fromVictor: "Victor, Happyness Manager",
      fromSofia: "Sofía Ramírez, AgFunder",
      quoteSofia:
        "Billy Birthday is fantastic. It helps us keep track of our teammates' birthdays and strengthen our team's social connections. Adding this feature to our Slack channel is a great way to enhance our human relations.",
      trustedBy: "Used by 1,000+ customers",
      readMoreReviews: "Read more reviews",
      howTo1_title: "Install in Slack",
      howTo1_desc:
        "One click secure installation. Then you're taken to a configuration page to select a channel and set things up.",
      howTo2_title: "Configure birth date collection",
      howTo2_desc:
        "Automatically (The bot will ask the users to enter their birth date) or manually (we provide a spreadsheet template for you to enter the dates).",
      howTo3_title: "You’re all set, start celebrating",
      howTo3_desc:
        "The bot will send notifications automatically. You can configure the time and channel via the settings.",
      howTo11_title: "Choose a channel",
      howTo11_desc:
        "You can add Billy to existing channels (public or private)",
      readyToWelcomeBilly: "Ready to welcome Billy in your workspace?",
      titlePreviewBilly: "Preview birthday messages in Slack | Billy Birthday",
      descriptionPreviewBilly:
        "Preview the messages that are sent by Billy Birthday to celebrate birthdays.",
      previewTitle: "Preview celebration messages sent by Billy",
      previewDescription:
        "Billy Birthday has a bunch of ideas on how to celebrate either birthdays or anniversaries. You can preview some of the messages right here by testing with your colleague names.",
      names: "Names",
      namesHint: "Write a name, press enter to add it to the list",
      style: "Style",
      otherExamples: "Other examples",
      ladiesAndGent: "Ladies and gentlemen 📣",
      whoop: "Whoop Whoop 🥳",
      todayWeAre: "Today we're celebrating",
      msgOutro: "Time to send some positive vibes 🏄‍♂️",
      lookingForPrivateChannel: "Looking for a Private channel?",
      tooManyChannelsWarning:
        "Too many channels are available in your Slack workspace, we weren't able to list them all here. To add Billy to a channel, please go to Billy's admin tab directly in your slack app right after submitting this page.",
      allWorkspaceMembers: "All workspace members",
      channelMembersOnly: "Channel members only",
      postCelebrationFor: "Post celebration messages for:",
      privateChannels: "Private channels",
      multipleChannels: "Multi-channels",
      titleForTeams: "Celebrate your team's birthdays in Microsoft teams",
      descriptionTeams:
        "Celebrate birthdays together at work. Never forget a teammate's birthday again. Celebrating birthdays is the most simple and quickest way to increase employee recognition.",
      descForTeams:
        "Celebrate birthdays together at work. Never forget a teammate's birthday again. Celebrating birthdays is the most simple and quickest way to increase employee recognition.",
      billyForTeams: "Billy for Teams",
      joinThousands: "Join thousands of companies using Billy",
      metaTitleFreeBDBot:
        "Free birthday bot for Slack - Install Billy today | Billy Birthday",
      metaDescriptionFreeBDBot:
        "Keep track of employee birthdays and anniversaries. A simple way to increase employee recognition and team cohesion. Install for free.",
      freeForeverUppercase: "FREE FOREVER",
      nonProfitTitle: "Are you a non-profit, coworking or student association?",
      nonProfitDesc: "Contact us for a free-forever access to {{appName}}.",
      descriptionFreeBDBot:
        "Suivez les anniversaires des employés de façon automatique. Un moyen simple d'augmenter la reconnaissance des employés et la cohésion de l'équipe. Vous pouvez installer Billy gratuitement dès aujourd'hui.",
      freePanelTitle: "Celebrate birthdays for free - up to 30 users",
      freePanelDesc:
        "Because we LOVE Startups and small businesses, we provide a free plan for these companies. For workspaces having 30 users or more, we have a very attractive pricing.",
      viewAlternatives: "Voir les alternatives",
      titleHR:
        "Human Resources, increase employee engagement today | BuddiesHR.com ",
      descriptionHR:
        "Your employees deserve the best experience. Use BuddiesHR to increase employee engagement now. Building a great culture of recognition starts here.",
      titleForHR: "BuddiesHR for Human Resources",
      hrQuote: "Your job is hard, get help for employee engagement.",
      arg1Title: "Increase employee engagement and team cohesion",
      arg2Title: "Distributed teams have never been closer",
      arg3Title: "Drive a culture of recognition",
      arg4Title: " Bring some fun into everyday routine",
      recognitionCultureDesc:
        "Encourage your team to send recognition messages to each other. Wishing birthdays is an enabler for sending good vibes.",
      benefits1_desc:
        "Have you ever felt bad discovering that you forgot a teammate's birthday few days after the date ? Won't happen again with Billy. Wishing a happy birthday is a simple thing, we make it simple for you to do it. ",
      benefits2_desc:
        "The best effort/impact ratio you can find to increase employee engagement and recognition is to automate birthday and anniversary celebrations. It is cross cultural, cross-religious, cross-generation, cross-timezone etc. And it makes people genuinely happy to receive some positive vibes during this special day.",
      benefits3_desc:
        "The way we work has changed. Working remotely and having a distributed team has a lot of advantages, but social events and celebrations need a little more help. We're here to fill the gap and build the Future of work together.",
      benefits4_desc:
        "The celebration messages from Billy really bring a fresh wave into every day work routine. We make sure to have the best copywriting to provide fun yet professional wishes.",
      titlePartnersSlack:
        "Slack partner offer for Billy the birthday bot | Billy Birthday",
      descriptionPartnersSlack:
        "Billy is a Slack partner and therefore offers a discount for Slack clients. Visit this page to benefit from the discount.",
      titlePartners: "Improve your Slack workspace with Billy Birthday",
      descPartners:
        "As a Slack client, benefit from a special discount for your Billy's and Slack subscription.",
      discount: "Discount",
      save25: "Save 25%",
      save25long: "25% off for the first year",
      save25long2: "Get free unlimited access up to 30 employees.",
      save25slack: "Save 25% off for Slack Pro / Business+",
      getoffer: "Get offer now",
      quickTour: "Discover Billy's settings with this quick tour",
      titlePricingBilly:
        "Pricing, use Billy for free | Billy, the birthday bot",
      descriptionPricingBilly:
        "Find the plan that applies best for your company. We provide a free plan forever for small companies. Use Billy Birthday to automate birthdays and work anniversaries in Slack",
      titleForRemote: "Engage a remote team with Slack add-ons",
      descForRemote:
        "Although engaging a remote team can be challenging, BuddiesHR can help facilitate team cohesion by bringing employees together.",
      titleFreeBDBot: "Free birthday bot for Slack",
      descriptionFreeBDBot:
        "Keep track of employee birthdays and anniversaries. A simple way to increase employee recognition and team cohesion. You can install Billy for free today.",
      metaDescriptionFreeBDBot:
        "Keep track of employee birthdays and anniversaries. A simple way to increase employee recognition and team cohesion. Install for free.",
      freePanelTitle: "Celebrate birthdays for free - up to 30 users",
      freePanelDesc:
        "Because we LOVE Startups and small businesses, we provide a free plan for these companies. For workspaces having 30 users or more, we have a very attractive pricing.",
      viewAlternatives: "View alternatives",
      titleRemoteTeams: "Slack apps for remote teams | BuddiesHR",
      descriptionRemoteTeams:
        "Although engaging a remote team can be challenging, Buddieshr can help facilitate team cohesion by bringing employees together.",
      titleReviews: "All Reviews for Billy the Birthday Bot for Slack",
      descriptionReviews:
        "Billy is the #1 app for automating birthdays in Slack. Read reviews from clients of Billy Birthday, rated 5/5 by hundreds of companies.",
      titleStartup:
        "Startups, increase employee engagement today | Billy Birthday",
      descriptionStartup:
        "Your employees deserve the best experience. Use Billy Birthday to increase employee engagement now. Building a great culture of recognition starts here.",
      titleForStartup: "Billy for Startups",
      descForStartup:
        "Your employees deserve the best experience. Use Billy Birthday to increase employee engagement now. Building a great culture of recognition starts here.",
      recognitionCultureDesc:
        "Encourage your team to send recognition messages to each other. Wishing birthdays is an enabler for sending good vibes.",
      ifWorkspaceLessThan30: "If your workspace has less than 30 users,",
      billyIsFree: "Billy is free.",
      metaTitleAirspeedAlternative:
        "Meet the best alternative to Airspeed - Try Billy Birthday",
      metaDescriptionAirspeedAlternative:
        "You are looking for Birthday Bot automation? You want to evaluate other options than GetAirspeed? Try Billy for free for 30 days.",
      alternativesAirspeedTitle: "Meet the best alternative to Airspeed",
      alternativesAirspeedDescription:
        "How Billy compares to Airspeed Celebrations? What's the best Slack Birthday app? What's the best alternative to Airspeed? Discover why Billy is a great alternative to getAirspeed celebrations.",
      AirspeedAlternativeIntro:
        "Airspeed propose several apps for Slack. Among them is a celebration app that can celebrate Birthdays and Work Anniversaries.",
      airspeedAlternative: "Airspeed alternative",
      billyDescription:
        " Created in 2023, Billy offers a modern solution for wishing birthdays with a human touch and a friendly yet professional tone. Unlike {{competitorName}}, Billy prioritizes the personal touch over the robotic tone, making it the ideal choice for modern companies using Slack to communicate. By using Billy to wish birthdays, you can improve employee recognition and create a more engaging work environment. So, if you want to make your employees feel valued on their special day, choose Billy for an unforgettable birthday celebration!",
      lookingForAlternativesSubtitleAirspeed:
        "Looking for Airspeed alternatives?",
      alternativeBdayBotArg1:
        " It's not just a birthday app. Use Billy to build your company culture.",
      alternativeBdayBotArg1_desc:
        "A simple app like Billy participate on the culture you want to share with your employees. Billy is a great addition to your team in order to set the tone for employee recognition.",
      alternativeBdayBotArg2: "Forget about the bot, meet the human touch",
      alternativeBdayBotArg2_desc:
        "What you don't want to see is a bot posting an automated message. At the contrary, what you want to achieve is to see a warm and fun message that triggers team bonding and reactions. Try Billy.",
      alternativeBdayBotArg3: "A modern app for modern companies",
      alternativeBdayBotArg3_desc:
        "Premium support (we reply fast, we are real humans), Week-end management to respect your employees by not sending notifications on week-ends. We embrace the new ways of working and provide options to facilitate these.",
      alternativeBdayBotArg4:
        "Billy is up to 75% cheaper and the trial last 30 days instead of 14.",
      alternativeBdayBotArg4_desc:
        "We believe that we provide a better service. We also propose a cheaper solution and a longer trial period. Billy's not just 2% cheaper, it is up to 75% cheaper than BirthdayBot. Trial is not just longer, it's doubled.",
      birthdayBotAlternativeIntro:
        "BirthdayBot has been a popular app on the Slack Birthday scene for quite some time now, serving thousands of clients. However, we'd like to introduce you to a newer and better option: Billy.",
      lookingForAlternativesSubtitle: "Looking for BirthdayBot alternatives?",
      alternativeTo: "Alternative to",
      versusBilly: "Versus Billy",
      billyBotForTeams: "Billy, the birthday bot for Microsoft Teams",
      tryOutforFree: "Try it for free for 30 days",
      getStarted: "Get Started",
      noCreditCardNothing: "No credit card required. Cancel anytime.",
      metaTitleBirthdaybotAlternative:
        "Meet the best alternative to BirthdayBot - Try Billy Birthday",
      metaDescriptionBirthdaybotAlternative:
        "Are you looking to keep track of your employees birthdays? Try Billy Birthday for free now and join thousands of companies using Billy.",
      alternativesBirthdayBotTitle: "Meet the best alternative to BirthdayBot",
      alternativesBdayBotDescription:
        "How Billy compares to BirthdayBot? What's the best Slack Birthday app? What's the best alternative to BirthdayBot? Discover why Billy is a great alternative to BirthdayBot.",
      trialPeriod: "Trial period",
      fourteenDays: "14 days",
      thirtyDays: "30 days",
      priceBirthdayBot: "$1,99 per user per month",
      priceBilly: "$1 per user per month",
      birthdayFeature: "Birthday and work anniversary messages",
      birthdayFeatureDesc:
        "Celebrate birthdays and work anniversaries of your coworkers.",
      funCopywriting: "Fun & Customizable Copywriting",
      funCopywritingFeature:
        "Billy posts warm and fun messages. You can choose to include GIFs or to customize each celebration.",
      workAnnivFeature: "Work anniversary messages",
      workAnnivDescFeature: "Celebrate work anniversaries of your coworkers",
      upcomingFeature: "List of upcoming events",
      upcomingDescFeature: "See next events and search for anyone's birthday.",
      optoutFeatureDesc:
        "Anyone can opt-out from birthdays or work anniversaries if they don't want to be celebrated.",
      weekendFeature: "Week-end management",
      weekendDescFeature:
        "When birthdays happen on week-ends, you can choose to delay the message on the next Monday or to celebrate on Friday.",
      autocollectFeature: "Auto-collection of dates",
      autocollectDescFeature:
        "Billy can reach out to your colleagues to collect their birth dates.",
      importFeature: "Spreadsheet bulk import",
      importDescFeature:
        "You can import people's dates inside a spreadsheet that we provide.",
      privateFeature: "Private channels",
      privateDescFeature:
        "Billy can be added to any public or private channels.",
      multiFeature: "Multi-channels",
      multiDescFeature:
        "Add Billy to any channel you'd like to use for birthday celebrations.",
      announceBotAlternativeIntro:
        "AnnounceBot is a birthday bot for Microsoft Teams. This bot integrates with your Teams environment, providing automated birthday reminders, personalized messages, and even virtual confetti explosions to make every team member feel special on their big day. ",
      alternativeAnnouncebotIntroContent:
        "Birthday bots have become increasingly popular in the workplace as they provide an efficient and fun way to acknowledge and celebrate birthdays within a team collaboration platform like Microsoft Teams. By automating the process of birthday reminders and celebrations, these bots make it easy for team members to connect and foster a sense of camaraderie, even in remote or dispersed work environments.",
      alternativeAnnouncebotIntroTitle: "introduction",
      billyDescriptionTeamsAgainstAnnounceBot:
        "Created in 2023, Billy offers a modern solution for announcing birthdays with a human touch and a friendly yet professional tone. Unlike AnnounceBot, Billy prioritizes the personal touch over the robotic tone, making it the ideal choice for modern companies using Slack to communicate. By using Billy to automate celebrations in Teams, you can improve employee recognition and create a more engaging work environment. So, if you want to make your employees feel valued on their special day, choose Billy for an unforgettable birthday celebration!",
      alternativeAnnounceBotArg4: "Billy provides a better service",
      alternativeAnnounceBotArg4_desc:
        "We believe that we provide a better service. Billy is also if free up to 30 users compared to 20 users for AnnounceBot.",
      announceBotAlternatives: "AnnounceBot alternative On Microsoft Teams",
      metaDescriptionBirthdaybotTeamsAlternative:
        "Are you looking to keep track of your employees birthdays? Try Billy Birthday for free and join thousands of companies celebrating their employees on MS Teams.",
      alternativesAnnounceBotTeamsTitle:
        "Best AnnounceBot alternative on Microsoft Teams",
      alternativesAnnounceTeamsDescription:
        "Are you looking to keep track of your employees birthdays? Try Billy Birthday for free and join thousands of companies celebrating their employees on MS Teams.",
      introduction: "Introduction",
      lookingForAlternativesSubtitleTeams:
        "Looking for BirthdayBot for Microsoft Teams?",
      metaTitleBirthdaybotTeamsAlternative:
        "Best AnnounceBot alternative on Microsoft Teams",
      metaDescriptionBirthdaybotTeamsAlternative:
        "Are you looking to keep track of your employees birthdays? Try Billy Birthday for free and join thousands of companies celebrating their employees on MS Teams.",
      alternativesBirthdayBotTeamsTitle:
        "Birthdaybot alternative for Microsoft Teams",
      metaDescriptionBirthdaybotTeamsAlternative:
        "Are you looking for a birthday bot for Microsoft Teams? You are at the right place. Meet Billy, the best choice to automate birthday and work anniversary celebrations on Microsoft Teams.",
      alternativesBdayBotTeamsDescription:
        "Are you looking for a birthday bot for Microsoft Teams? You are at the right place. Meet Billy, the best choice to automate birthday and work anniversary celebrations on Microsoft Teams.",
      lookingForAlternativesSubtitleTeams:
        "Looking for BirthdayBot for Microsoft Teams?",
      billyDescriptionTeams:
        " Created in 2023, Billy offers a modern solution for wishing birthdays with a human touch and a friendly yet professional tone. Unlike BirthdayBot, Billy is available on Microsoft Teams. It also prioritizes the personal touch over the robotic tone, making it the ideal choice for modern companies using Slack to communicate. By using Billy to wish birthdays, you can improve employee recognition and create a more engaging work environment. So, if you want to make your employees feel valued on their special day, choose Billy for an unforgettable birthday celebration!",
      donutAlternativeIntro:
        "Donut is a well known app for Slack. It's mainly known for its random coffee feature to push people to meet in an organisation. It also provides a feature for birthday and work anniversary celebrations. However, today, we'd like to introduce you to a newer and better option: Billy, the birthday bot.",
      metaTitleDonutAlternative:
        "Meet the best alternative to Donut - Try Billy Birthday",
      metaDescriptionDonutAlternative:
        "Are you looking to keep track of your employees birthdays? Try Billy Birthday for free now and join thousands of companies using Billy. This is the best alternative to donut.com",
      alternativesDonutTitle: "Meet the best alternative to Donut",
      alternativesDonutDescription:
        "How Billy compares to Donut Celebrations? What's the best Slack Birthday app? What's the best alternative to Donut? Discover why Billy is a great alternative to Donut.com celebrations.",
      lookingForAlternativesSubtitleDonut: "Looking for Donut alternatives?",
      donutAlternatives: "Donut alternative",
      alternativeBdayBotArg4Donut:
        "Billy is 6x cheaper (for 40 people, Billy costs $20 per month whereas $119 for Donut)",
      alternativeBdayBotArg4_descDonut:
        "We believe that we provide a better service. We also propose a cheaper solution and a longer trial period. Billy's not just 2% cheaper, it is 6 times cheaper than Donut. Trial is not just longer, it's doubled.",
      priceDonut: ">$3 per user per month",
      metaTitleAlternatives:
        "Why Billy is the best alternative to other Birthday bots? Try it for free.",
      metaDescriptionAlternatives:
        "Discover why Billy is the best alternative to your birthday bot and how it can improve your company culture and employee recognition.",
      alternativesTitle:
        "Why Billy is the best alternative to other Birthday bots?",
      alternativesDescription:
        "We're on a journey to help companies increase employee recognition and happiness for a better workplace.",
      viewAllAlternatives: "View all alternatives",
      metaTitleFAQ: "Frequently Asked Questions | Billy Birthday",
      metaDescriptionFAQ:
        "Get answers on most-demanded questions about Billy Birthday.",
      faq9Title: "How does Billy count the number of users in the workspace?",
      faq9Content:
        "Billy counts the number of active users in the channels where it is included (excluding bots or any inactive accounts and excluding guests users to only take employees into account).",
      faq10Title:
        "How do you calculate the fee when someone leaves or joins in the middle of a month?",
      faq10Content:
        "The rate adjusts every time a slack user is removed or added. There is a pro-rata mechanism. So for example, if you spend 1/3 of the month at 180 users and 2/3 of the month at 120, the rate will be: (1/3 * 180 + 2/3*120) * ($_Current_price)",
      faq1Title: "How to move Billy Birthday to another channel?",
      faq1Content:
        "In order to change the channel where Billy Birthday posts its celebration messages, you can go into the settings of the bot. In Slack, click on Billy Birthday user > Home > Select another channel.",
      faq2Title: "How to change the time when the celebrations are posted ?",
      faq2Content:
        "In order to change the time when Billy Birthday posts its celebration messages, you can go into the settings of the bot. In Slack, click on Billy Birthday user > Home > Select another time.",
      faq3Title: "How do I change my birthday ?",
      faq3Content:
        "If you want to change your own birthday, you need to go into Billy Birthday's profile in Slack > Home tab and then you'll be able to edit your birth date.",
      faq4Title: "How do I change my work anniversary ?",
      faq4Content:
        "If you want to change your own work anniversary date, you need to go into Billy Birthday's profile in Slack > Home tab and then you'll be able to edit your work anniversary.",
      faq5Title: "How to collect birth dates automatically?",
      faq5Content:
        "When setting up Billy Birthday you'll have the choice between Entering birth dates manually via a spreadsheet and telling the bot to collect them automatically by asking the users. You can activate/deactivate auto-collect in the settings of each channel where Billy is included.",
      faq6Title: "How to import birthdays in bulk via an excel spreadsheet ?",
      faq6Content:
        "If you want to set birthdays in bulk, go into the bot's profile in Slack > Home tab then click on Import Birthdays. The spreadsheet template will be sent to you via a Slack message. You can then fill-in this file and then upload it again in the same conversation.",
      faq7Title: "How to add new celebration messages?",
      faq7Content:
        "If you want to add celebration messages, please contact us via contact@billybirthday.com.",
      faq8Title: "How much does Billy Birthday cost?",
      faq8Content:
        "Billy is free for teams with less than 30 users. It's also free to install for larger teams (no credit card required) because we offer a 30 days free trial. Paid plan depends on the number of users in your workspace, to see how much Billy will cost for your team, please visit our Pricing page. If you're interested in custom integrations or custom features, please contact support@billybirthday.com.",
      faqInvoice: "How to find my invoices?",
      faqInvoiceContent:
        "Navigate to Billy's admin panel in your Slack workspace (in the sidebar, click on the app name at the bottom of the screen). You'll find a button called 'View invoices' in 'Billing' section. This is also the place where you can update your billing details.",
      faqRename: "How to rename the bot in Slack?",
      faqRenameContent:
        "Navigate to Billy's admin panel in your Slack workspace (in the sidebar, click on the app name at the bottom of the screen). Click on 'About' tab then on 'Configuration' button. You'll find a section called 'Bot User', you can click on 'Edit' in this section. You should now see a modal called 'Edit bot name'.",
      faqSubteam: "How can I use Billy for a subteam of my workspace?",
      faqSubteamContent:
        "If you want to install Billy on your Slack workspace but only accessible for a subteam or a specific channel and therefore you see a pricing discrepancy between your usage and our calculations, send us an email at contact@billybirthday.com.",
      faqDescription:
        "Get answers on most-demanded questions about Billy Birthday",
      birthday: "birthday",
      formatted: "Formatted",
      classic: "Classic",
      explanationPrivateChannel:
        "If you want to use Billy in a private Channel, you'll have the option in the admin panel of Billy within Slack app. Please submit this page first. (you can leave the channel selector empty by selecting the first item: '-')",
      explanationPrivateChannelAlfy:
        "If you want to use Alfy in a private Channel, you'll have the option in the admin panel of Alfy within Slack app. Please submit this page first. (you can leave the channel selector empty by selecting the first item: '-')",
      titleSetup:
        "Setup Billy birthday | Automate birthdays in Slack | Billy Birthday",
      descriptionSetup:
        "Setup Billy birthday for your Slack. Never miss your teammates' birthdays. Use Billy Birthday to automate birthdays and work anniversaries in Slack.",
      recommended: "Recommended",
      setupBillyTitle: "Setup Billy Birthday",
      setupWorkAnniv: "Do you wish to celebrate work anniversaries too?",
      submit: "Submit",
      yes: "Yes",
      no: "No",
      moreAboutDateCollectionMethod: "More about date collection methods",
      setupAutomaticallyCollect: "Automatically collect Birth dates",
      setupAutomaticallyCollectDesc:
        "Billy Birthday will reach out to the users to make sure they provide the dates.",
      setupManuallyCollect: "Enter/Import birth dates manually",
      setupManuallyCollectDesc:
        "We provide a spreadsheet template for you to enter the dates",
      setupBillyDescription:
        "You'll be able to edit these settings at any time inside your Slack workspace",
      metaTitleLove: "Wall of love, read what users say about us | BuddiesHR",
      descriptionLove:
        "Reviews and testimonials from customers of Buddieshr Slack applications.",
      titleInstall: "Install Billy birthday bot in your workspace",
      descInstall:
        "Whether you're using Slack or Microsoft Teams, you can install Billy and start elevating your employee engagement today.",
      multiLang: "Multi-languages",
      metatitleBestSlackApps: "Best Slack apps (Updated {{date}})",
      descriptionBestSlackApps:
        "Find a curated list of the best Slack apps and must haves for your workspace. Improve productivity and work experience thanks to these integrations. ",
      metatitleSlackExperience:
        "Spotlight on Apps: Enhance Your Slack Experience!",
      descriptionSlackExperience:
        "We’re excited to introduce four fantastic apps from the Slack App Directory. Check them out below and see how they can help you collaborate better, and have more fun at work!",
      celebrationType: "Celebrations type",
      birthdays: "Birthdays",
      workAnniversaries: "Work Anniversaries",
      workAnniversary: "Work Anniversary",
      metaTitleStany: "Slack Standup Bot | #1 Slack App",
      descriptionContentStany: "Automate daily Standups, check-ins or any status updates. Asynchronously collect status updates directly in Slack and streamline the work of your remote team.",
      metaTitleDonutAlternativeBuddies: "Donut alternatives, try BuddiesHR - Engage remote employees in Slack, connect coworkers, celebrate wins, recognize good work.",
      descriptionContentDonutAlternativeBuddies:
        "Find out why BuddiesHR is better than Donut for engaging employees. Connect coworkers for a chat, celebrate milestones, recognize good work and more.",
        birthdayWorkAnniversaries: "Birthdays and Work Anniversaries",
        coffeeMatching: "1:1 Coffee Matching",
        kudosRecognition: "Kudos and Recognition",
        SurveysPollsFeature: "Surveys and Polls",
        orgChartFeature: "Org-Chart",
        standupFeature: "Standup bot",
    },
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});

export default i18n;
