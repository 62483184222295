import { Button, Tag } from "antd";
import React from "react";
import { BellOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { SHOW_MARKETING_BANNER } from "@buddieshr/utils/constants";
import styles from "@buddieshr/styles/components/marketingBanner.module.scss";
import Link from "next/link";
import { useRouter } from "next/router";
const MarketingBanner = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const urlExempted = router.pathname.substring(1, 10) === "subscribe";
  return SHOW_MARKETING_BANNER && !urlExempted ? (
    <div className={styles.MarketingBanner_wrapper}>
      <Tag className={styles.tag} color="red">
        <BellOutlined style={{ fontSize: "14px" }} />
        &nbsp; NEW
      </Tag>
      &nbsp;
      {t("cultureReport")}
      &nbsp;
      <div>
        <Link
          id="CTA_marketing_banner"
          href={"/startup-culture-insights-report-2024"}
        >
          <Button
            className={styles.cta}
            type="primary"
            style={{ marginLeft: "10px" }}
          >
            {t("readReport")}
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MarketingBanner;
