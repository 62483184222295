/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import "../utils/i18n";
import i18n from "i18next";

import "@buddieshr/styles/globals.css";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ConfigProvider, theme } from "antd";
import dynamic from "next/dynamic";
import MarketingBanner from "@buddieshr/components/marketingBanner";
import Script from "next/script";
import { gtmVirtualPageView, isLocalhost } from "@buddieshr/utils/utils";
import { GA_MEASUREMENT_ID, LOCAL_STORAGE } from "@buddieshr/utils/constants";
import SourceContext from "../../sourceContext";
import { PRODUCTS } from "@buddieshr/utils/constants-products";
import posthog from "posthog-js";
const CrispWithNoSSR = dynamic(() => import("@buddieshr/components/crisp"));

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const [refSource, setRefSource] = useState("landing");
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  useEffect(() => {
    const mainDataLayer = {
      pageTypeName: pageProps.page || null,
      url: router.pathname,
    };

    if (!isLocalhost()) {
      gtmVirtualPageView(mainDataLayer);
      if (!firstLoadDone) {
        posthog.init("phc_4tPvCyMzrkP41EtHejoJZ2Eh55IKF827Rtt5pj6Yok3", {
          api_host: "https://eu.posthog.com",
        });
        setFirstLoadDone(true);
      }
    }

    const refParam = router?.query?.ref;
    const gadParam =
      router?.query?.gad_source || router?.query?.gclid ? "gads" : null;
    const storageRef = localStorage.getItem(LOCAL_STORAGE.REF);
    if (
      (((refParam && refParam !== "") || (gadParam && gadParam !== "")) &&
        !storageRef) ||
      storageRef === ("" || "undefined")
    ) {
      localStorage.setItem(LOCAL_STORAGE.REF, gadParam || refParam);
      setRefSource(gadParam || refParam || "landing_buddieshr");
    } else {
      const pathSplit = router.pathname.split("/");
      const firstRoute = pathSplit.length > 0 ? pathSplit[1] : null;
      const appslugs = PRODUCTS.map((a) => a.slug);

      let refParamFallback = "landing_";
      if (appslugs.includes(firstRoute)) {
        refParamFallback = `${refParamFallback}${firstRoute}`;
      } else {
        refParamFallback = `${refParamFallback}buddieshr`;
      }
      setRefSource(gadParam || storageRef || refParam || refParamFallback);
    }
  }, [pageProps, router]);

  useEffect(() => {
    i18n.changeLanguage(router.locale);
  }, [pageProps, router.locale]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#020849",
          borderRadius: 8,
          fontFamily: "'silka', sans-serif",
          boxShadow: "none",
          colorLink: "#1724aa",
        },
      }}
    >
      <SourceContext.Provider value={refSource}>
        <MarketingBanner />
        <Component {...pageProps} refSource={refSource} />
        <CrispWithNoSSR />
      </SourceContext.Provider>
      <>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', "${GA_MEASUREMENT_ID}");
        `}
        </Script>
        <Script id="facebook-pixel" strategy="afterInteractive">
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '861933272709457');
fbq('track', 'PageView');`}
        </Script>
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=861933272709457&ev=PageView&noscript=1"
          />
        </noscript>
        <Script id="rb2b">
          {`
            !function () {var reb2b = window.reb2b = window.reb2b || [];
            if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];
            reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);
            args.unshift(method);reb2b.push(args);return reb2b;};};
            for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}
            reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;
            script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
            var first = document.getElementsByTagName("script")[0];
            first.parentNode.insertBefore(script, first);};
            reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("ZQO92DH42DN7");}();
    `}
        </Script>
      </>
    </ConfigProvider>
  );
}
